@tailwind base;
@tailwind components;
@tailwind utilities;
html {
  background-color: 
  #0e0e0e;

font-family: 'Poppins', sans-serif;
    overflow-x: hidden;
  scroll-behavior: smooth;
}
body {
  margin: 0;
 
}

